export default {
  ingredients: [
    { value: "1", type: "solid", group: "sugar", label: "Sugar" },
    { value: "2", type: "solid", group: "starch", label: "Maize starch" },
    { value: "3", type: "solid", group: "rice", label: "Raw rice" },
    { value: "4", type: "solid", group: "oats", label: "Oats" },
    {
      value: "5",
      type: "solid",
      group: "bicarbonate",
      label: "Sodium bicarbonate, chemical and biological yeast",
    },
    { value: "6", type: "solid", group: "coffe", label: "Powder coffee" },
    {
      value: "7",
      type: "solid",
      group: "nuts",
      label: "Chestnuts, almonds, walnuts",
    },
    {
      value: "8",
      type: "solid",
      group: "chocolate",
      label: "Chocolate powder",
    },
    {
      value: "9",
      type: "solid",
      group: "coco",
      label: "Freshly grated coconut",
    },
    { value: "10", type: "solid", group: "wheat", label: "Wheat flour" },
    { value: "11", type: "solid", group: "fuba", label: "Corn meal" },
    {
      value: "12",
      type: "liquid",
      group: "liquid",
      label: "Liquids (water, milk, drinks, yogurts, oils and etc.)",
    },
    {
      value: "13",
      type: "solid",
      group: "fats",
      label: "Butter, lard or vegetable fat",
    },
    { value: "14", type: "liquid", group: "honey", label: "Honey" },
    {
      value: "15",
      type: "solid",
      group: "peanutb",
      label: "Peanut butter",
    },
    {
      value: "16",
      type: "solid",
      group: "powder",
      label: "Sweet and sour sprinkles",
    },
    { value: "17", type: "solid", group: "salt", label: "Salt" },
    { value: "18", type: "solid", group: "cheese", label: "Grated cheese" },
  ],
  solidMeasures: [
    { value: "1", label: "teaspoon" },
    { value: "2", label: "dessert spoon" },
    { value: "3", label: "tablespoon" },
    { value: "4", label: "american cup" },
    { value: "5", label: "cup" },
    { value: "6", label: "ounce (oz)" },
    { value: "7", label: "gram (g)" },
    { value: "8", label: "pound / libra (lb)" },
    { value: "9", label: "kilogram (kg)" },
  ],

  liquidMeasures: [
    { value: "1", label: "teaspoon" },
    { value: "2", label: "dessert spoon" },
    { value: "3", label: "tablespoon" },
    { value: "4", label: "american cup" },
    { value: "5", label: "cup" },
    { value: "6", label: "fluid ounce (fl oz)" },
    { value: "7", label: "milliliters (ml)" },
    { value: "8", label: "quart" },
    { value: "9", label: "liters (l)" },
  ],
}
