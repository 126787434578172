export default {
  title: "Culinary measures converter",
  description:
    "Choose an ingredient and convert the main culinary measures (cup of tea, american cup, teaspoons, dessert spoon and tablespoon), to grams (g), kilograms (kg), milliliters (ml), liters (l), ounce (oz), pound / libra (lb), as well as temperatures (Celsius, Fahrenheit and Kelvin)",
  formheader: {
    measures: "Measures",
    temperatures: "Temperatures",
  },
  form: {
    ingredient: "Ingredient",
    measure1: "Measure 1",
    measure2: "Measure 2",
    qtd: "Amount",
    selectPlaceholder: "Select...",
  },
  temperatureform: {
    scale1: "Scale 1",
    scale2: "Scale 2",
    degrees: "Degrees",
    selectPlaceholder: "Select...",
  },
  keywords: {
    title: "Keywords",
    words:
      "measure converter * culinary measures * ingredient measures * culinary portions * ingredient portions * teaspoon to grams * tablespoon to grams * american cup to grams * tea cup to grams * teaspoon to american cup * tablespoon to american cup * teaspoon to tea cup * tablespoon to tea cup * american cup to ml * tea cup to ml * american cup to liter * tea cup to liter * ounce to grams * fluid ounce to ml * pounds to kilograms * libra to kilograms * Celsius to Fahrenheit * Celsius to Kelvin * Fahrenheit to Celsius * Fahrenheit to Kelvin",
  },
  content: {
    h2: "Conversion tables",
    table1: {
      decimal: "Decimal",
      fraction: "Fraction",
    },
    contentTable: {
      th1: "Measure",
      th2: "Amount",
    },
    p1:
      "Decimal values, which can appear as a result of a conversion, can be interpreted based on the following scheme",
    p2:
      "The values used as a reference for each ingredient are shown in the tables below",
    p3:
      "Source: this information was collected through research on websites and blogs specialized in cooking. The material accessed showed small variations in the quantities reported and some adjustments were made to minimize them",
  },
  copyText: "Developed by",
  page_not_found: {
    title: "Page not found",
    button: "Back to home page",
  },
}
